import React from 'react'
import './styles/Footer.css'
function Footer() {
  return (
    <div className='footer-container'>
        <p>Gilberto Rodriguez</p>
        <p> Copyright &copy; 2024, All Rights Reserved</p>

    </div>
  )
}

export default Footer